import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { H1_Legacy, H2_Legacy, Links, P_Legacy } from '.';
import { pages } from 'features/home/urls';

const ErrorMessage = {
  404: 'Page not found',
  500: 'Server Error',
};

const useStyles = makeStyles(() => ({
  header1: { marginBottom: '0px' },
  header2: { marginTop: '-10px' },
  content: { margin: '32px 0 ' },
}));

type ErrorPageProps = {
  errorCode?: number;
};

export const ErrorPage: FC<ErrorPageProps> = ({ errorCode = 500 }) => {
  const classes = useStyles();
  const router = useRouter();

  const msg = ErrorMessage[errorCode] ?? ErrorMessage[500];

  let content = (
    <P_Legacy className={classes.content}>
      It looks like we&#39;re experiencing some technical issues, and our engineers are onto it. In the meantime try to
      refresh the page, or feel to contact us at <Links type="SupportEmail" /> if the problem persists.
    </P_Legacy>
  );

  if (errorCode === 404) {
    content = (
      <P_Legacy className={classes.content}>
        The page you requested could not be found. Please go back to the homepage or contact us at{' '}
        <Links type="SupportEmail" />.
      </P_Legacy>
    );
  }

  const handleClick = () => {
    router.push(pages.PUBLIC_PAGE);
  };

  return (
    <Grid item sm={12} md={10} lg={8}>
      <Box textAlign="center">
        <H1_Legacy className={classes.header1} variant="super-hero">
          {errorCode}
        </H1_Legacy>
        <H2_Legacy className={classes.header2}>{msg}</H2_Legacy>
        {content}
        <Button variant="outlined" onClick={handleClick}>
          Go to home page
        </Button>
      </Box>
    </Grid>
  );
};
