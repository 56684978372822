import React from 'react';
import { ErrorPage, MaterialLayout, TruePage } from 'components';

const Page: TruePage = () => {
  return <ErrorPage errorCode={404} />;
};

Page.options = {
  layout: MaterialLayout,
  headerColor: 'light-grey',
  title: 'Support',
  pageLinks: [],
};

export default Page;
