import { useGlobalCmsData } from 'hooks/use-global-cms-data';
import React, { FC } from 'react';
import { Link } from './link';

type CommonLinkProps = {
  type: 'SupportEmail';
};

export const Links: FC<CommonLinkProps> = ({ type }) => {
  const { data } = useGlobalCmsData();

  if (type === 'SupportEmail' && data) {
    return <Link href={`mailto:${data.email}`}> {data.email}</Link>;
  }
  return <></>;
};
