import React, { FC } from 'react';
import { getHeaderColorScheme, Header } from '../header';
import { Footer, PageOptions } from 'components';
import { ResponsiveGrid } from '.';
import { makeStyles, Theme, useTheme } from '@material-ui/core';
import { SiteMap } from 'components/site-map';
import { useGlobalCmsData } from 'hooks/use-global-cms-data';
import { useAuth } from 'context';
import { HeaderLinksResponsive } from 'components/header-links-responsive';
import { PartnerBranding } from 'services/cms';

const useStyles = makeStyles<Theme, PageOptions>(() => ({
  main: (options) => ({
    minHeight: '100vh',
    backgroundColor: options?.backgroundColor,
  }),
}));

export const MaterialLayout: FC<{ options: PageOptions; partnerBranding?: PartnerBranding }> = ({
  children,
  options,
  partnerBranding,
}) => {
  const classes = useStyles(options);
  const { data: globalCmsData, isLoading } = useGlobalCmsData();
  const { isUserInitialised, user } = useAuth();
  const theme = useTheme();
  const footerTheme = options.footer?.theme ?? 'light';
  const headerColours = getHeaderColorScheme(theme, options.headerColor);

  return (
    <>
      <main className={classes.main} style={{ minHeight: '100vh' }}>
        {!options.noHeader && (
          <Header
            key="mainHeader"
            headerColor={options.headerColor}
            pageLinks={options.pageLinks}
            showReferralBanner
            partnerBranding={partnerBranding}
            cmsPageLinks={options.cmsPageLinks}
          />
        )}
        {options.noGutters ? children : <ResponsiveGrid>{children}</ResponsiveGrid>}
        <HeaderLinksResponsive
          isDarkMode={headerColours.isDarkMode ?? false}
          textColor={headerColours.textColor ?? theme.palette.Vegemite.main}
          pageLinks={options.pageLinks}
          headerColor={options.headerColor}
          bodyLinks={true}
          onClick={null}
          cmsPageLinks={options.cmsPageLinks}
        />
      </main>

      {(options.footer?.globalFooter || options.footer?.pageFooters?.length > 0 || options.footer?.globalSitemap) &&
        !isLoading &&
        isUserInitialised && (
          <div style={{ position: 'relative' }}>
            {options.footer?.globalSitemap && (
              <SiteMap data={globalCmsData} isAuthenticated={!!user} mode={footerTheme} />
            )}
            {(options.footer?.globalFooter || options.footer?.pageFooters?.length > 0) && (
              <Footer
                content={[
                  ...(options.footer?.pageFooters ?? []),
                  ...(options.footer?.globalFooter && globalCmsData?.footer?.value
                    ? [globalCmsData?.footer?.value]
                    : []),
                ]}
                backgroundColour={footerTheme === 'dark' ? theme.palette.Dark1.main : theme.palette.Dark6.main}
                textColour={footerTheme === 'dark' ? theme.palette.Dark7.main : theme.palette.Dark2.main}
              />
            )}
          </div>
        )}
    </>
  );
};
